import { useCallback, useEffect } from 'react';
import useAuthentication from '~/hooks/use-authentication';
import { useLocation } from 'react-router-dom';
import api from '~/lib/api';
import useTiering from '~/hooks/use-tiering';

function useHubspot() {
  const { token, email } = useAuthentication();
  const location = useLocation();
  const tiering = useTiering();
  const hasSoftwarePackageConnectivityStarter = tiering.hasSoftwarePackage('connectivity-starter');

  const loadChatWidget = useCallback(async () => {
    // if (email?.includes('@onomondo.com')) return;
    try {
      const {
        data: { token },
      } = await api.post('/hubspot/get-visitor-token');
      window.hsConversationsSettings = {
        identificationEmail: email,
        identificationToken: token,
        loadImmediately: true,
      };
      if (window.HubSpotConversations && !hasSoftwarePackageConnectivityStarter)
        window.HubSpotConversations.widget.load();
    } catch (err) {
      console.log('Error loading Hubspot live chat', err);
    }
  }, [email]);

  useEffect(() => {
    if (token) loadChatWidget();

    return () => {
      if (window.HubSpotConversations) window.HubSpotConversations.widget.remove();
    };
  }, [loadChatWidget, token]);

  // Send every page change to Hubspot. Their library gets the url by itself.
  useEffect(() => {
    const isLoggedIn = !!token;
    if (!isLoggedIn) return;

    window._hsq = window._hsq || [];
    window._hsq.push(['trackPageView']);
  }, [location, token]);
}

export default useHubspot;
