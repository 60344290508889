import styled, { css } from 'styled-components';
import Button from '~/components/Button';
import Input from '~/components/Input';
import { ModalParagraph as _ModalParagraph } from '~/components/Text';

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  position: fixed;
`;

export const SignUpCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.lightest};
  z-index: 2;
  max-width: 36rem;
  height: 90%;
  max-height: 902px;
  border-radius: 1rem;
  @media (min-width: 10rem) {
    padding: 64px;
    width: 504px;
  }
  @media (max-height: 900px) {
    padding: 64px;
    width: 33rem;
    overflow-y: auto;
  }
`;

export const OnoLogo = styled.img`
  width: 158px;
  margin-bottom: 48px;
`;

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}px;`}
`;

export const ContinueBtn = styled(Button)`
  display: flex;
  width: 100%;
  height: 2.85rem;
  background-color: black;
  color: white;
  border-radius: 2.15rem;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const GoogleBtn = styled(Button)`
  display: grid;
  grid-template-columns: 25% 50% 25%;
  align-items: center;
  font-family: Roboto;
  width: 100%;
  height: 2.85rem;
  background-color: ${props => props.theme.lightest};
  border: 1px solid ${props => props.theme.lightMedium};
  border-radius: 2.15rem;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledCheckbox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: ${props => props.theme.color.version3.accent.main[9]};
`;

export const AllowDataProcessing = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-align: start;
  color: ${props => props.theme.dark};
  margin-top: 24px;
  @media (max-height: 900px) {
    margin: 1rem 0;
  }
`;

export const DataProcessingContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckboxError = styled.p`
  color: ${props => props.theme.danger};
`;

export const GoBackBtn = styled.button`
  color: ${props => props.theme.darkMedium};
  margin-top: 16px;
  margin-bottom: 6px;
  background-color: white;
  border: none;
  display: flex;
  white-space: nowrap;
  font-size: 14px;
  gap: 0.35rem;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`;

export const NameInputGroup = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledLink = styled.a`
  color: ${props => props.theme.color.version3.accent.main[9]};
  &:hover {
    cursor: pointer;
  }
`;

export const VerifyEmailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  gap: 8px;
`;

export const PasswordInputWrapper = styled.div`
  position: relative;
`;

export const EyeIcon = styled.div`
  position: absolute;
  right: 14px;
  top: 28px;
  cursor: pointer;
`;

export const StyledInput = styled(Input)`
  min-height: 3rem;
  [class*='SelectElLabel'] {
    font-size: 12px;
  }
`;

export const FlexedInput = styled(Input)`
  flex-grow: 1;
  min-height: 3rem;
`;

export const StyledLabelSpan = styled.span`
  align-self: ${props => props.align};
  display: flex;
`;

export const StyledLabelContainer = styled.p`
  color: ${props => (props.disabled ? props.theme.lightMedium : props.theme.darkest)};
  font-size: 12px;
  margin-left: 5px;
`;

export const StyledTextWrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 40px;
`;

export const SubTitleWrapper = styled.div`
  margin-bottom: 48px;
`;

export const StyledContinueBtnWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

export const SimpleInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const messageBaseCss = css`
  padding: ${props => props.theme.spacing[3]};
  border: 1px solid;
  border-radius: ${props => props.theme.radius[3]};
  gap: 8px;
`;

export const InfoMessage = styled.div`
  ${messageBaseCss}
  background-color: ${props => props.theme.color.version3.neutral.main[2]};
  border-color: ${props => props.theme.color.version3.neutral.main[6]};
`;

export const WarningMessage = styled.div`
  ${messageBaseCss}
  background-color: ${props => props.theme.color.version3.warning.main[2]};
  border-color: ${props => props.theme.color.version3.warning.main[6]};
`;

export const ErrorMessage = styled.div`
  ${messageBaseCss}
  background-color: ${props => props.theme.color.version3.error.main[2]};
  border-color: ${props => props.theme.color.version3.error.main[6]};
`;

export const PromotionMessage = styled.div`
  ${messageBaseCss}
  background-color: ${props => props.theme.color.version3.accent.main[2]};
  border-color: ${props => props.theme.color.version3.accent.main[6]};
`;

export const MessageTop = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${props => props.theme.spacing[2]};

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing[2]};
  }
`;

export const MessageButton = styled(Button)`
  width: 100%;
  background-color: ${props => props.theme.color.version3.neutral.mainAlpha[3]};
  border-radius: ${props => props.theme.radius.full};
`;

export const ModalParagraph = styled(_ModalParagraph)`
  text-align: center;
  color: ${props => props.theme.color.version3.neutral.main[11]};
`;
